import "./CardStep.scss";

import { Add, Delete, Remove, RemoveShoppingCart } from "@mui/icons-material";
import { Grid, Typography, Button, Stack, Divider, Input, Skeleton, Card } from "@mui/joy";
import { ModalAlert } from "../../global_components/Modal";
import { useEffect, useState } from "react";
import { useCart } from "../../../providers/cartProducts";
import StepBody from "../StepBody";
import { cartList } from "../../../interfaces/cartInterface";
import { useNavigate } from "react-router-dom";

export default function CartStep(props: { handleStepper: (value: number) => void }) {
	const cart = useCart();
	const [modalClear, setModalClear] = useState<boolean>(false);
	const [modalRemove, setModalRemove] = useState<boolean>(false);
	const [toRemove, setToRemove] = useState(null);
	const [shopList, setShopList] = useState<cartList[]>([]);
	const [totalValue, setTotalValue] = useState<number>(0);
	const nav = useNavigate();

	function handleCloseRemoveModal() {
		setToRemove(null);
		setModalRemove(false);
	}

	function handleCloseClearModal() {
		setModalClear(false);
	}

	function handleRemoveItem() {
		cart.removeItem(toRemove.projectId);
		setModalRemove(false);
	}

	function handleClearCart() {
		cart.clearCart();
		setModalClear(false);
	}

	function handleQntChange(index, quantity, item) {
		cart.setShipmentLoading(true);
		if (quantity === 0) {
			setToRemove(item);
			setModalRemove(true);
		} else {
			cart.itemQuantity(index, quantity);
		}
	}

	async function handleBuildCartList() {
		const newList = cart.cartList.map((shopItem) => {
			const newProject = cart.project.find((projectItem) => shopItem.projectId === projectItem?.projectId);
			// const newProduct = cart.product.find((productItem) => shopItem?.productId === productItem.Id);

			return {
				projectId: newProject?.projectId,
				title: newProject?.title,
				model: cart.product?.name,
				quantity: shopItem?.quantity,
				price: cart.product?.price,
				availableUnit: cart.product?.availableQuantity,
				thumbnail: newProject?.thumbnail,
			};
		});
		let newValue = newList.reduce((agg, item) => agg + item.price * item.quantity, 0);
		setShopList([...newList]);
		setTotalValue(newValue);
	}

	useEffect(() => {
		handleBuildCartList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalValue, cart.cartList, cart.loading, cart.product, cart.project, cart.shipmentLoading]);

	useEffect(() => {}, [cart.shipment]);

	return (
		<StepBody handleStepper={props.handleStepper} step={0} productValue={totalValue / 100}>
			<Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography level="title-lg">Produtos</Typography>
				<Button variant="outlined" color="danger" onClick={() => setModalClear(true)} size="sm">
					<RemoveShoppingCart />
					Limpar Carrinho
				</Button>
			</Grid>
			<Grid key="--" md={12} xs={12}>
				<Card>
					<Stack divider={<Divider sx={{ margin: "1em 0" }} />} padding="0.5em">
						{cart.loading === true && (
							<Grid
								container
								md={12}
								xs={12}
								spacing={2}
								sx={{ display: "flex", flexGrow: "1", justifyContent: "space-between" }}
							>
								<Grid md={12}>
									<Skeleton
										animation="wave"
										variant="rectangular"
										width="100%"
										height="200px"
										sx={{ borderRadius: "1rem" }}
									/>
								</Grid>
								<Grid md={12}>
									<Skeleton
										animation="wave"
										variant="rectangular"
										width="100%"
										height="200px"
										sx={{ borderRadius: "1rem" }}
									/>
								</Grid>
							</Grid>
						)}
						{cart.loading !== true &&
							shopList.map((item, index) => (
								<Grid
									key={item.projectId}
									md={12}
									xs={12}
									container
									spacing={2}
									sx={{ display: "flex", flexGrow: "1", justifyContent: "space-between" }}
								>
									<Grid md={2} xs={4} container alignItems="center">
										<img
											className="mugImage"
											src={item?.thumbnail}
											alt="img"
											onClick={() => nav("/criar/" + item.projectId)}
											style={{ cursor: "pointer" }}
										/>
									</Grid>
									<Grid md={10} xs={8} container>
										<Grid md={5} xs={12}>
											<Typography level="title-lg">{item?.title}</Typography>
											<Typography level="body-sm">{item.model}</Typography>
										</Grid>
										<Grid md={3} xs={8} sx={{ gap: "0.5em" }}>
											<Typography level="body-sm" textAlign="center">
												Qntd.
											</Typography>
											<Stack direction="row">
												<Button
													onClick={() => {
														if (item.quantity !== 1) {
															handleQntChange(index, item.quantity - 1, item);
														} else {
															if (shopList.length <= 1) {
																setModalClear(true);
															} else {
																setToRemove(item);
																setModalRemove(true);
															}
														}
													}}
													sx={{ width: "42px" }}
													color={item.quantity !== 1 ? "primary" : "danger"}
												>
													{item.quantity === 1 && <Delete />}
													{item.quantity !== 1 && <Remove />}
												</Button>
												<Input
													type="number"
													className="qtdInput"
													value={item.quantity}
													onChange={(evt) => handleQntChange(index, +evt.target.value, item)}
													variant="plain"
													slotProps={{ input: { style: { overflow: "visible", textOverflow: "clip" } } }}
													sx={{ fontSize: 16, paddingInline: "0", overflow: "visible" }}
												/>
												<Button
													onClick={() => {
														handleQntChange(index, item.quantity + 1, item);
													}}
													sx={{ width: "42px" }}
												>
													<Add />
												</Button>
											</Stack>
										</Grid>
										<Grid md={4} xs={4}>
											<Typography textAlign="end" level="body-sm">
												Total
											</Typography>
											<Typography textAlign="end" level="title-lg">
												R$ {((item.price * item.quantity) / 100)?.toFixed(2)}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							))}
					</Stack>
				</Card>
			</Grid>

			<ModalAlert
				openModal={modalRemove}
				title="Deseja remover este item?"
				content={toRemove?.Title}
				confirmTextColor="danger"
				confirmText="Remover"
				cancelText="Cancelar"
				handleConfirmation={handleRemoveItem}
				handleCancelOperation={handleCloseRemoveModal}
				handleCloseModal={handleCloseRemoveModal}
			/>

			<ModalAlert
				openModal={modalClear}
				title="Limpar Carrinho?"
				content="Deseja remover todos os itens do carrinho?"
				confirmTextColor="danger"
				confirmText="Limpar Carrinho"
				cancelText="Cancelar"
				handleConfirmation={handleClearCart}
				handleCancelOperation={handleCloseClearModal}
				handleCloseModal={handleCloseClearModal}
			/>
		</StepBody>
	);
}
