import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Landing from "./pages/landing/landing";
import NotFound from "./pages/error/NotFound";
import Create from "./pages/create/create";
import Collection from "./pages/user/collection/collection";
import Profile from "./pages/user/profile/profile";
import Login from "./pages/login/loginAmplify";
import Test from "./pages/test/test";
import TopLayout from "./layout/topLayout";
import Cart from "./pages/user/cart/cart";

import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import extendedTheme from "./styles/extendedTheme";

import { PreviousRouteProvider } from "./providers/RouterTracker";
import { UserProvider, useUser } from "./providers/UserInfo";
import { CartProductsProvider } from "./providers/cartProducts";

import { useState } from "react";
import UserArea from "./pages/user/area/area";
import Impersonate from "./pages/impersonate/impersonate";
import Produtos from "./pages/produtos/produtos";
import Pedidos from "./pages/pedidos/pedidos";
import Order from "./pages/order/order";
import BaseForm from "./providers/Errors";
import Fast from "./pages/fast/fast";
import Layout from "./components/global_components/Layout";

function App() {
	const [, setIsEdit] = useState(null);

	const AuthGuard = (props) => {
		const user = useUser();

		if (user.user) {
			return props.children;
		} else {
			sessionStorage.setItem("redirectTo", window.location.pathname);
			return <Navigate to="/login" />;
		}
	};

	return (
		<CssVarsProvider theme={extendedTheme}>
			<CssBaseline>
				<BrowserRouter>
					<Layout>
						<UserProvider>
							<PreviousRouteProvider>
								<CartProductsProvider>
									<BaseForm>
										<Routes>
											<Route path="/" element={<TopLayout />}>
												<Route index element={<Landing />} />
												<Route
													path="criar"
													element={
														<AuthGuard>
															<Fast />
														</AuthGuard>
													}
												/>
												<Route
													path="criar/:projectID"
													element={
														<AuthGuard>
															<Create />
														</AuthGuard>
													}
												/>
												<Route
													path="pedidos/:orderID?"
													element={
														<AuthGuard>
															<Order />
														</AuthGuard>
													}
												/>
												<Route path="login" element={<Login />} />
												<Route
													path="test"
													element={
														<AuthGuard>
															<Test />
														</AuthGuard>
													}
												/>
												<Route
													path="impersonate"
													element={
														<AuthGuard>
															<Impersonate />
														</AuthGuard>
													}
												/>
												<Route
													path="produtos"
													element={
														<AuthGuard>
															<Produtos />
														</AuthGuard>
													}
												/>
												<Route
													path="todos-pedidos"
													element={
														<AuthGuard>
															<Pedidos />
														</AuthGuard>
													}
												/>
												<Route
													path="colecao"
													element={
														<AuthGuard>
															<Collection toEdit={setIsEdit} />
														</AuthGuard>
													}
												/>
												<Route
													path="colecao/:userName"
													element={
														<AuthGuard>
															<Collection toEdit={setIsEdit} />
														</AuthGuard>
													}
												/>
												<Route
													path="carrinho"
													element={
														<AuthGuard>
															<Cart />
														</AuthGuard>
													}
												/>
												<Route
													path="profile"
													element={
														<AuthGuard>
															<Profile />
														</AuthGuard>
													}
												/>
												<Route
													path="minha-area"
													element={
														<AuthGuard>
															<UserArea />
														</AuthGuard>
													}
												/>
												<Route path="*" element={<NotFound />} />
											</Route>
										</Routes>
									</BaseForm>
								</CartProductsProvider>
							</PreviousRouteProvider>
						</UserProvider>
					</Layout>
				</BrowserRouter>
			</CssBaseline>
		</CssVarsProvider>
	);
}

export default App;
