import mugVideo from "../../assets/mugCreationVideo.mp4";
import fizzLogo from "../../assets/logozz-color-white.png";
import landingImage from "../../assets/girlmug.webp";

import Lottie from "lottie-react";
import supportData from "../../lotties/support.json";
import coWorkData from "../../lotties/co_workers.json";
import creativityData from "../../lotties/creativity.json";

import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/joy";
import { Helmet } from "react-helmet";

import "./landing.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import LoginMenu from "../../components/login_menu/LoginMenu";
import MobileDrawerMenu from "../../components/login_menu/MobileDrawerMenu";
import { useUser } from "../../providers/UserInfo";
import { Email, LinkedIn, WhatsApp } from "@mui/icons-material";
import { storeCoupon } from "../../services/couponService";

function Landing() {
	const [productIndex, setProductIndex] = useState(0);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [continueProject, setContinueProject] = useState(JSON.parse(sessionStorage.getItem("localProject")));
	const [searchParams] = useSearchParams();
	const coupon = searchParams.get("ref");

	const user = useUser();
	const nav = useNavigate();
	const myRef = useRef(null);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		setContinueProject(JSON.parse(sessionStorage.getItem("localProject")));

		if (coupon) {
			storeCoupon(coupon);
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [coupon]);

	function handleTopMenu() {
		if (isMobile) {
			return (
				<Box className="headerTopBarMobile">
					<Link to="/" className="logoTop" style={{ margin: "0", height: "2em" }}>
						<img src={fizzLogo} style={{ height: "2em" }} alt="Fizz logo" />
					</Link>
					{!user.user && (
						<>
							<Link className="menuLink" to="/login">
								Entrar
							</Link>
							<Button size="lg" onClick={() => nav("/login")}>
								Cadastre-se
							</Button>
						</>
					)}
					{user.user && <MobileDrawerMenu color="primary" variant="solid" />}
				</Box>
			);
		}
		if (!isMobile) {
			return (
				<div className="headerTopBar">
					<div className="topWrapper">
						<Link to="/" className="logoTop">
							<img src={fizzLogo} style={{ height: "48px" }} alt="fizz logo" />
							<Typography className="logoTitle">Fizz Online Personalizados</Typography>
						</Link>
						<Box display="flex" gap="1em" justifyContent="flex-end" alignContent="flex-end">
							{continueProject && (
								<Link className="menuLink" to="/criar/convidado">
									CONTINUAR PROJETO
								</Link>
							)}
							{/* <Link className="menuLink" to="/explorar"> Explorar </Link> */}
							{!user.user && (
								<Link className="menuLink" to="/login">
									ENTRAR
								</Link>
							)}
							{user.user && (
								<Link className="menuLink" to="/colecao">
									COLEÇÃO
								</Link>
							)}
							{!user.user && (
								<Button className="createBtn" onClick={() => nav("/login")} size="sm">
									CRIAR CANECA
								</Button>
							)}
							{user.user && <LoginMenu color="primary" variant="solid" />}
						</Box>
					</div>
				</div>
			);
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (productIndex < 2) {
				setProductIndex(productIndex + 1);
			} else {
				setProductIndex(0);
			}
		}, 3000);
	}, [setProductIndex, productIndex]);

	return (
		<div className="snapWrapper">
			<Helmet>
				<title>Fizz Online - Canecas Personalizadas</title>
			</Helmet>
			<header className="landingHeader snapChild">
				{/* {handleTopMenu()} */}
				<Stack className="headerStack" direction={"row"} alignItems="center">
					<Grid className="headerInfo" container>
						<Grid md={12} />
						<Grid md={12}>
							<Typography className="landingTitle">DESBLOQUEIE SUA CRIATIVIDADE COM O FIZZ ONLINE</Typography>
							<Typography level="h4" color="secondary">
								Crie sua caneca personalizada em minutos e receba em casa
							</Typography>
						</Grid>
						<Grid md={12}>
							<Button fullWidth={isMobile} color="primary" size="xl" onClick={() => nav("/criar")}>
								Criar minha caneca!
							</Button>
						</Grid>
					</Grid>
					<Grid className="headerImg" container>
						<img className="landingImg" src={landingImage} alt="Uma pessoa entregando presente para outra" />
					</Grid>
				</Stack>
			</header>

			<main className="snapChild" id="main">
				<Stack>
					<div className="homeWrapper" style={{ backgroundColor: "var(--primary)" }}>
						<Grid container padding="2em" ref={myRef} spacing={{ xs: 2, md: 0 }}>
							<Grid md={4} xs={12} container>
								<Grid md={12} xs={12} justifyContent={{ xs: "center", md: "flex-start" }}>
									<Typography level="h2" textColor="white" marginBottom="0.5em">
										Seja você mesmo
									</Typography>
									<Typography level="title-md" textColor="white">
										Use o nosso editor para criar canecas que sejam do seu jeito!
										<br />
										Adicione imagens, textos e até figurinhas. O limite é sua imaginação!
									</Typography>
								</Grid>
								<Grid md={12} xs={12}>
									<Lottie animationData={creativityData} width={"100%"} controls={false} />
								</Grid>
							</Grid>
							<Grid md={1} />
							<Grid md={7} xs={12} sx={{ display: "flex", alignItems: "center" }}>
								<Box className="editorVideo">
									<video
										loop
										autoPlay
										muted
										disablePictureInPicture
										playsInline
										style={{ maxWidth: "100%", borderRadius: "0.5rem" }}
										onClick={() => nav("/criar")}
									>
										<source src={mugVideo} type="video/mp4" />
									</video>
								</Box>
							</Grid>
							<Grid md={1} />
						</Grid>
					</div>
					<div className="homeWrapper">
						<Grid container padding="6em 2em" spacing={{ xs: 2, md: 0 }}>
							<Grid container md={5} xs={12} gap={"2em"}>
								<Grid md={12} xs={12} display={"flex"} flexDirection={"column"} gap={"1em"}>
									<Typography level="h2">Sobre nós</Typography>
									<Typography level="title-md">
										Somos uma empresa brasileira com o objetivo de levar agilidade e originalidade na confecção de
										produtos personalizados
									</Typography>
									<Typography level="title-md">
										Localizada em São Paulo, a selva de pedra, queremos trazer mais cor para o mundo, eternizar os bons
										momentos da vida e demonstrar o quanto amamos nossa arte
									</Typography>
								</Grid>
								<Grid md={12} xs={12} display={"flex"} gap={"1em"}>
									<LinkedIn
										sx={{ cursor: "pointer", color: "black", scale: "2", paddingLeft: "4px" }}
										onClick={() => (window.location.href = "https://www.linkedin.com/company/fizzonline")}
									/>
								</Grid>
							</Grid>
							<Grid md={2} xs={12} />
							<Grid container md={5} xs={12}>
								<Grid md={12} xs={12} display={"flex"} flexDirection={"column"} gap={"1em"}>
									<Typography level="h2">Sobre o produto</Typography>
									<Typography level="title-md">
										O editor Fizz permite que você personalize sua caneca do jeito que você quiser, de uma maneira
										rápida e intuitiva!
									</Typography>
									<Typography level="title-md">
										A caneca é elegante e resistente, ideal para o dia a dia. Feita de porcelana de alta qualidade,
										possui capacidade de 325ml e acabamento impecável. Compatível com micro-ondas e lava-louças, é
										perfeita para casa ou escritório. Um item prático e sofisticado!
									</Typography>
								</Grid>
								{/* <Grid md={12} xs={12} display={'flex'} justifyContent={'center'}>
									<img src={fizzLogo} style={{ height: "200px" }} />
								</Grid> */}
							</Grid>
							{/* <Grid md={12} xs={12} container justifyContent="center">
								<Typography level="h1" textAlign="center">
									Criado por nossa comunidade
								</Typography>
							</Grid>

							<Grid md={12} xs={0} />
							<Grid md={12} xs={12} container justifyContent="center">
								<Grid md={12} sx={{ position: "relative" }}>
									<EmblaCarousel options={carouselOptions} slides={mockData.map((item) => {
										return (
											<Box key={item.title}>
												<CardPreview
													id={item.id}
													width={isMobile ? '200px' : '300px'}
													height={isMobile ? '200px' : '300px'}
													title={item.title}
													imgSrc={cattoMug}
													showFavorite={false}
													menu={false}
												/>
											</Box>
										);
									})} />
								</Grid>
							</Grid>
							<Grid md={12} xs={12} />

							<Grid
								md={12}
								xs={12}
								sx={{
									justifyContent: "center",
									display: "flex",
									marginBottom: "1em",
									textAlign: "center",
								}}
							>
								<Typography level="h3">
									Monte sua caneca também.
									<br />É de graça e sem compromisso!
								</Typography>
							</Grid>
							<Grid md={4} xs={12} />
							<Grid md={4} xs={12} sx={{ justifyContent: "center", display: "flex" }}>
							</Grid>
							<Grid md={4} xs={12} /> */}
						</Grid>
					</div>
					<div className="homeWrapper" style={{ backgroundColor: "var(--accent)" }}>
						<Grid container padding="2em 2em 0 2em">
							<Grid md={6} xs={12} container marginBottom="1em">
								<Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
									<Lottie
										animationData={coWorkData}
										height={300}
										width={300}
										controls={false}
										// style={{ margin: "0" }}
									/>
								</Grid>
							</Grid>
							<Grid md={6} xs={12} container>
								<Grid
									md={12}
									xs={12}
									container
									justifyContent={{ md: "flex-end", xs: "center" }}
									padding={{ md: "0", xs: "1em" }}
									marginTop="0em"
									gap={{ md: 0, xs: 2 }}
								>
									<Typography level="h2" textColor="white" textAlign={isMobile ? "center" : "justify"}>
										Produz canecas?
									</Typography>
									<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "justify"}>
										Trabalhamos com parcerias, vamos crescer juntos!
									</Typography>
									<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "end"}>
										Seja usando nosso editor para seus clientes ou produzindo canecas que nossos clientes criaram
									</Typography>
								</Grid>
								<Grid md={12} xs={12} />
								<Grid
									md={12}
									xs={12}
									container
									justifyContent={{ md: "flex-end", xs: "center" }}
									alignSelf={{ md: "flex-start" }}
									marginBottom={{ md: 0, xs: "1em" }}
								>
									<Button size="xl" onClick={() => nav("/parceria")}>
										Como funcionam as parcerias
									</Button>
								</Grid>
							</Grid>

							<Grid
								md={12}
								xs={12}
								container
								sx={{
									backgroundColor: "var(--primary)",
									padding: "2em",
									borderTopLeftRadius: "0.5rem",
									borderTopRightRadius: "0.5rem",
								}}
							>
								<Grid md={6} xs={12} container>
									<Grid md={12} xs={12}>
										<Typography level="h2" textColor="white">
											Faltou explicar algo ou quer bater um papo?
										</Typography>
										<Typography level="body-lg" textColor="white">
											Entre em contato conosco nos nossos canais de atendimento
										</Typography>
										<Stack direction="row" marginTop="1em">
											<Tooltip title="contato@fizzonline.com.br" variant="plain" color="primary">
												<LinkedIn
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() => window.open("https://www.linkedin.com/company/fizzonline", "_blank")}
												/>
											</Tooltip>
											<Tooltip title="contato@fizzonline.com.br" variant="plain" color="primary">
												<Email
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() => window.open("mailto:contato@fizzonline.com.br", "_blank")}
												/>
											</Tooltip>
											<Tooltip title="(11) 93458-4758" variant="plain" color="primary">
												<WhatsApp
													color="secondary"
													sx={{ scale: "1.5", marginRight: "1em", cursor: "pointer" }}
													onClick={() =>
														window.open(
															"https://wa.me/5511934584758?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20o%20fizzonline.com.br",
															"_blank",
														)
													}
												/>
											</Tooltip>
										</Stack>
									</Grid>
									<Grid md={12} xs={12} sx={{ margin: isMobile ? "0" : "3em" }} />
								</Grid>
								<Grid md={6} xs={12}>
									<Lottie animationData={supportData} width={"100"} />
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Stack>
			</main>

			<footer className="snapChild">
				<Typography level="title-lg" textColor="white">
					Fizz Online, um produto João de Barro
				</Typography>
			</footer>
		</div>
	);
}

export default Landing;
