import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { Button, Divider, Table, Input, Option, Select, Stack, Typography, Snackbar } from "@mui/joy";
import PageHeader from "../../components/global_components/PageHeader";
import { useNavigate } from "react-router-dom";
import { editOrder, getAllOrders } from "../../services/orderService";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import Order from "../../interfaces/orderInterface";
import { ModalBig } from "../../components/global_components/Modal";

export default function Pedidos() {
	const nav = useNavigate();
	const [orderList, setOrderList] = useState<Order[]>([]);
	const [editItem, setEditItem] = useState<Order>(null);
	const [snackBar, setSnackBar] = useState({ show: false, success: false, message: "" });
	const [searchCliente, setSearchCliente] = useState("");
	const [statusFilter, setStatusFilter] = useState("");

	const filteredOrders = orderList.filter((order) => {
		const matchesCliente = !searchCliente || order.customer.name.toLowerCase().includes(searchCliente.toLowerCase());
		const matchesStatus = !statusFilter || order.orderStatus === statusFilter;
		return matchesCliente && matchesStatus;
	});
	const orderStatuses = ["processing", "cancelled", "payment_received", "in_production", "shipping", "delivered"];

	useEffect(() => {
		fetchAuthSession()
			.then((x: AuthSession) => {
				const token = decodeToken(x.tokens.accessToken.toString());
				if (!token["cognito:groups"].includes("admins")) {
					nav("/");
				}
				getAllOrders()
					.then((orders: Order[]) => {
						orders.sort((a, b) => a.customer.name.localeCompare(b.customer.name));
						setOrderList(orders);
					})
					.catch((err) => console.error("Erro ao consultar usuários:", err));
			})
			.catch((err) => {
				console.error("Erro ao recuperar token", err);
			});
	}, [nav]);

	const handleOrderUpdate = async () => {
		try {
			const response = await editOrder(editItem.orderId, editItem.customerId, editItem.orderStatus);
			setOrderList((prevList) => prevList.map((order) => (order.orderId === editItem.orderId ? editItem : order)));
			setSnackBar({ show: true, success: true, message: response["message"] });
			setEditItem(null);
		} catch (err) {
			setSnackBar({ show: true, success: false, message: err.response.data.message });
		}
	};
	return (
		<>
			<Stack
				spacing={2}
				padding={{ md: "2em", xs: "0.5em 0.5em" }}
				divider={<Divider sx={{ margin: "1em 0" }} />}
				maxWidth={1400}
				width="100vw"
			>
				<PageHeader title="Gestão de Pedidos"></PageHeader>
				<Stack direction="row" spacing={2} alignItems="center">
					<Input
						placeholder="Filtrar por Cliente"
						value={searchCliente}
						onChange={(e) => setSearchCliente(e.target.value)}
					/>
					<Select
						placeholder="Filtrar por Status"
						value={statusFilter}
						onChange={(e, newValue) => setStatusFilter(newValue)}
					>
						<Option value="">Status (todos)</Option>
						<Option value="processing">Em Processamento</Option>
						<Option value="cancelled">Cancelado</Option>
						<Option value="payment_received">Pago</Option>
						<Option value="shipping">Em Transporte</Option>
						<Option value="delivered">Entregue</Option>
						<Option value="in_production">Em Produção</Option>
					</Select>
				</Stack>
				<Table>
					<thead>
						<tr>
							<th style={{ width: "15%" }}>
								<Typography level="body-md" fontWeight="lg">
									Cliente
								</Typography>
							</th>
							<th style={{ width: "30%" }}>
								<Typography level="body-md" fontWeight="lg">
									Produtos
								</Typography>
							</th>
							<th style={{ width: "10%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Custo
								</Typography>
							</th>
							<th style={{ width: "8%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Descontos
								</Typography>
							</th>
							<th style={{ width: "7%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Frete
								</Typography>
							</th>
							<th style={{ width: "10%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Status
								</Typography>
							</th>
							<th style={{ width: "15%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Número do Pedido
								</Typography>
							</th>
							<th style={{ width: "5%" }}>
								<Typography level="body-lg" fontWeight="lg">
									Ações
								</Typography>
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrders.map((order) => (
							<tr key={order.orderId}>
								<td style={{ width: "15%" }}>
									<Typography>{order.customer.name}</Typography>
								</td>
								<td style={{ width: "30%", whiteSpace: "pre-line" }}>
									<Typography>{order.orderItems.map((item) => item.product.name).join("\n")}</Typography>
								</td>
								<td style={{ width: "10%" }}>
									<Typography>{(order.totalAmount / 100).toFixed(2).replace(".", ",")}</Typography>
								</td>
								<td style={{ width: "8%" }}>
									<Typography>{((order.totalDiscounts || 0) / 100).toFixed(2).replace(".", ",")}</Typography>
								</td>
								<td style={{ width: "7%" }}>
									<Typography>{(order.selectedShipment.price / 100).toFixed(2).replace(".", ",")}</Typography>
								</td>
								<td style={{ width: "10%" }}>
									<Typography>{order.orderStatus}</Typography>
								</td>
								<td style={{ width: "15%", whiteSpace: "pre-line" }}>
									<Typography>{order.orderId}</Typography>
								</td>
								<td style={{ width: "5%" }}>
									<Button size="sm" onClick={() => setEditItem(order)}>
										Editar
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Stack>

			<ModalBig
				onClose={() => setEditItem(null)}
				open={editItem !== null}
				modalTitle="Atualizar status do pedido"
				buttonTitle="Salvar"
				onBtnClick={handleOrderUpdate}
			>
				<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 2 }}>
					<Stack>
						<Typography level="body-md" fontWeight="bold">
							Cliente
						</Typography>
						<Typography level="body-md">{editItem?.customer.name}</Typography>
					</Stack>
					<Stack>
						<Typography level="body-md" fontWeight="bold">
							Pedido
						</Typography>
						<Typography level="body-md">{editItem?.orderId}</Typography>
					</Stack>
					<Stack>
						<Typography level="body-md" fontWeight="bold">
							Status
						</Typography>
						<Select
							value={editItem?.orderStatus || ""}
							onChange={(_, value) =>
								setEditItem({
									...editItem,
									orderStatus: value as unknown as Order["orderStatus"],
								})
							}
						>
							{orderStatuses.map((status) => (
								<Option key={status} value={status}>
									{status}
								</Option>
							))}
						</Select>
					</Stack>
				</Stack>
			</ModalBig>
			<Snackbar
				autoHideDuration={3000}
				open={snackBar.show}
				variant="solid"
				size={"md"}
				color={snackBar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					setSnackBar({ ...snackBar, show: false });
				}}
			>
				{snackBar.message}
			</Snackbar>
		</>
	);
}
