import imageCompression from "browser-image-compression";
import { useRef, useState } from "react";
import "./ImageCardCrop.scss";
import { VisuallyHiddenInput } from "./InputFileUpload";
import UploadWizard from "../create/object_menu/img_menu/upload_wizard/upload_wizard";

export default function ImageCardCrop(props: { onChange: (b64: string) => void; src?: string; aspect?: number }) {
	const [img, setImg] = useState<File | null>(null);
	const fileInput = useRef(null);

	const loadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files[0];

		if (file.size / (1024 * 1024) >= 1) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};
			imageCompression(file, options)
				.then((result) => {
					if (result) {
						setImg(result);
					}
				})
				.catch((err) => {
					console.error("Error compressing image", err);
				});
		} else {
			setImg(file);
		}
	};

	return (
		<>
			<div
				className="imageCardCrop"
				style={{ background: `url(${props.src})` }}
				onClick={(e) => {
					fileInput.current && fileInput.current.click();
				}}
			>
				<VisuallyHiddenInput
					onChange={loadPhoto}
					ref={fileInput}
					type="file"
					onClick={(evt: any) => {
						evt.target.value = null;
					}}
					multiple={false}
					accept=".jpg,.jpeg,.png,.webp,.gif,.svg,.avif"
				/>
			</div>
			<UploadWizard
				hideFormat
				handleImageDone={async (b64) => props.onChange(b64)}
				aspect={props.aspect}
				image={img}
				handleClose={() => setImg(null)}
			/>
		</>
	);
}
