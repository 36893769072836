import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { func } from "prop-types";

const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 65000,
});

const apiImageProcessingClient = axios.create({
	baseURL: process.env.REACT_APP_API_IMAGE_PROCESSING_URL,
	timeout: 65000,
});

async function addSub(req) {
	const info = await fetchAuthSession();
	req.headers.Authorization = "Bearer " + info.tokens.accessToken.toString();
	const userId = sessionStorage.getItem("impersonatedUser") ?? info.userSub;
	req.url = req.url.replaceAll("@userID", userId);
	return req;
}

apiClient.defaults.headers.post["Content-Type"] = "application/json";
apiClient.interceptors.request.use(addSub);
export default apiClient;

apiImageProcessingClient.defaults.headers.post["Content-Type"] = "application/json";
apiImageProcessingClient.interceptors.request.use(addSub);
export { apiImageProcessingClient };
