import apiClient from "../plugins/apiClient";

export async function validateCoupon(couponCode: string) {
	try {
		const response = await apiClient.post(`/customer/@userID/coupon/validate`, { couponCode: couponCode });
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export function storeCoupon(couponCode: string) {
	localStorage.setItem("coupon", couponCode);
	localStorage.setItem("CouponDate", new Date().toISOString());
}

export function removeCoupon() {
	localStorage.removeItem("coupon");
	localStorage.removeItem("CouponDate");
}
