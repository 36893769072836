import { apiImageProcessingClient } from "../plugins/apiClient";
import { Media } from "../interfaces/mediaInterface";
import axios from "axios";
import { b64toBlob } from "./mediaService";

export enum Effects {
	REMOVE_BACKGROUND = "REMOVE_BACKGROUND",
	CARTOONIFY = "CARTOONIFY",
}

export interface ImageEffects {
	pre_signed_url: string;
	effect: Array<Effects>;
}

export interface ImageEffectResponse {
	pre_signed_url: string;
}

export async function postImageEffect(effect: ImageEffects): Promise<ImageEffectResponse> {
	try {
		return (await apiImageProcessingClient.post("/v0/image/effect", effect)).data;
	} catch (error) {
		console.error(error);
	}
}

export async function getUploadUrl(media: Media) {
	try {
		return (
			await apiImageProcessingClient.post("/v0/image/upload", {
				media_id: media.mediaId,
				media_type: media.mediaType + "/" + media.mediaFormat,
			})
		).data;
	} catch (error) {
		console.error(error);
	}
}

export async function uploadImage(media: Media): Promise<string> {
	try {
		const response = await getUploadUrl(media);
		const uploadUrl = response.pre_signed_url;
		const mediaType = media.mediaType;
		const mediaFormat = media.mediaFormat;

		const contentType = `${mediaType}/${mediaFormat}`;
		const file = new File([b64toBlob(media.media)], "x", { type: contentType });
		await axios.put(uploadUrl, file, {
			headers: { "Content-Type": contentType },
		});
		return uploadUrl;
	} catch (error) {
		console.error(error);
	}
}
